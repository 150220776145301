<template>
  <svg
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.61872 9.36872C7.27701 9.71043 6.72299 9.71043 6.38128 9.36872L2.88128 5.86872C2.53957 5.52701 2.53957 4.97299 2.88128 4.63128C3.22299 4.28957 3.77701 4.28957 4.11872 4.63128L7 7.51256L9.88128 4.63128C10.223 4.28957 10.777 4.28957 11.1187 4.63128C11.4604 4.97299 11.4604 5.52701 11.1187 5.86872L7.61872 9.36872Z"
    />
  </svg>
</template>
